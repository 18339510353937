
import { mixins, Component } from 'nuxt-property-decorator';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-custom-html',
})
export default class CustomHTML extends mixins(WidgetMixin) {
  private get noContainerCalculated(): boolean {
    return this.data?.slug === 'ezebra' ? true : this.noContainer;
  }
}
